import { reduce } from 'lodash';
import { convertTimeToTimeZoneForDisplay, generateRandomColor } from './commonHelper';
import type { DashboardMetricData } from '../constants/Interfaces/DashboardTypes';

interface ChartDataSetType {
  label: string[]
  borderColor: string
  data: string[] | number[]
}

interface ChartMetricData {
  labels: string[]
  datasets: ChartDataSetType[]
}

export const convertListToDisplayTimeList = (data: any[]): any[] => {
  return data.map((item) => convertTimeToTimeZoneForDisplay(item))
}

export function convertDataForLineChartMetrics (data: any): ChartMetricData {
  const labels = new Set(); // To store unique labels
  const dateKeys = Object.keys(data).filter((key) => key !== 'total');

  // Collect unique labels
  dateKeys.forEach((date) => {
    const dateData = data[date];
    for (const label in dateData) {
      labels.add(label);
    }
  });

  // Initialize datasets array
  const datasets = Array.from(labels).map((label: any, index: number) => ({
    label,
    borderColor: generateRandomColor(index),
    data: Array(dateKeys.length).fill(0)
  }));

  // Populate data points
  dateKeys.forEach((date, index) => {
    const dateData = data[date];
    datasets.forEach((dataset, i) => {
      dataset.data[index] = dateData[dataset.label] || 0;
    });
  });

  return {
    labels: convertListToDisplayTimeList(Object.keys(data).filter(key => key !== 'total')),
    datasets
  };
}

export function formatChartResponsePayload (data: any): DashboardMetricData {
  const metricData: DashboardMetricData = {};

  for (const timestamp in data) {
    const updatedList = reduce(
      data[timestamp] || [],
      (acc, { metricName, metricCount }) => ({ ...acc, [metricName]: metricCount }),
      {});

    metricData[timestamp] = updatedList;
  }

  return metricData;
}

export function convertDataForPieChartMetrics (data: any): any {
  const bgColors: Record<string, string> = {
    resolved: '#4cb64c',
    pending: '#f3a638',
    error: '#e3503e'
  };

  const chartData: Record<string, any> = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: []
    }]
  };

  for (const key in data) {
    if (key.includes('total') || !data[key]) {
      continue;
    }
    chartData.labels.push(key[0].toUpperCase() + key.slice(1));
    chartData.datasets[0].data.push(data[key]);
    chartData.datasets[0].backgroundColor.push(bgColors[key]);
  }

  return chartData;
}
